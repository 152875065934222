.App {
  height: 100vh;
  height: 100dvh; /* use dynamic vh if possible */
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.content-container {
  height: 100vh;
  width: 100vw;
  height: 100dvh; /* use dynamic vh if possible */
  width: 100dvw; /* use dynamic vw if possible*/
  background: linear-gradient(-75deg, #32666c 0%, #1e5157 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.content-container h1 {
  margin: 0;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input {
  font-size: 22px;
  border-radius: 5px;
  border: none;
  padding: 5px;
  max-width: calc(100vw - 150px);
  max-width: calc(100dvw - 150px); /* use dynamic vh if possible */
}

.input-button {
  margin-left: 20px;
  font-size: 16px;
  padding: 5px 10px;
  background-color: #59858b;
  border: none;
  border-radius: 5px;
  color: white;
}

.input-button:hover {
  background-color: #1e5157;
  cursor: pointer;
}

.download-button-container {
  height: 150px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.download-icon-wrapper {
  transition: all .5s;
  overflow: hidden;
}

.visible {
  cursor: pointer;
}

.download-icon {
  height: 100%;
  user-select: none;
}